import * as React from 'react';
import TextField from '@mui/material/TextField';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputTimeRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
const MDTimePicker = () => {
  const [time, setTime] = React.useState(new Date());

  const handleTimeChange = (newValue) => {
    setTime(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MultiInputTimeRangeField
          slotProps={{
            textField: ({ position }) => ({
              label: position === 'start' ? 'From' : 'To',
            }),
          }}
        />
    </LocalizationProvider>
  );
};

export default MDTimePicker;
