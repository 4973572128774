// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPaper-root .flatpickr-calendar {
    border: none !important; /* Removes any border */
    box-shadow: none !important; /* Removes any shadow */
  }
  
  .flatpickr-day.today {
    background: none !important;
    color: inherit !important;
    border: none !important;
  }
.flatpickr-input{
    display: none;
  }

  /* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}`, "",{"version":3,"sources":["webpack://./src/layouts/pages/booking/booking.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,uBAAuB;IAChD,2BAA2B,EAAE,uBAAuB;EACtD;;EAEA;IACE,2BAA2B;IAC3B,yBAAyB;IACzB,uBAAuB;EACzB;AACF;IACI,aAAa;EACf;;EAEA,qBAAqB;AACvB;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".MuiPaper-root .flatpickr-calendar {\n    border: none !important; /* Removes any border */\n    box-shadow: none !important; /* Removes any shadow */\n  }\n  \n  .flatpickr-day.today {\n    background: none !important;\n    color: inherit !important;\n    border: none !important;\n  }\n.flatpickr-input{\n    display: none;\n  }\n\n  /* custom scrollbar */\n::-webkit-scrollbar {\n  width: 20px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #d6dee1;\n  border-radius: 20px;\n  border: 6px solid transparent;\n  background-clip: content-box;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #a8bbbf;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
