// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    /* colors */
    --light-pink: #eee3fc;
    --lighter-pink: #fbf7ff;
    --main-color: #257EEA;
    /* measures */
  }
  .custom-video-nav {
    border-radius: 8px;
    background-color: var(--main-color);
    width: 100%;
    border: none !important;
    overflow-x: auto;
    padding-left: 4px;
  }
  
  .custom-video-nav::-webkit-scrollbar {
    height: 15px;
  }
  
  .custom-video-nav-item {
    display: block;
    align-items: center;
    padding: 2px 6px;
    background-color: var(--main-color);
    margin: 6px 0 5px;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
    font-family: 'Montserrat' sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
    cursor: pointer;
    width: 5rem;
    text-align: center;
  }
  .custom-video-nav-item.active {
    background-color: white;
    color: var(--main-color);
  }
  
  .custom-video-nav > ul {
    border: none;
  }
  
  .custom-video-nav::-webkit-scrollbar {
    width: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/styles/videos/directory-tabs.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,aAAa;EACf;EACA;IACE,kBAAkB;IAClB,mCAAmC;IACnC,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,mCAAmC;IACnC,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,oCAAoC;IACpC,mBAAmB;IACnB,gBAAgB;IAChB,kCAAkC;IAClC,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ","sourcesContent":["\n:root {\n    /* colors */\n    --light-pink: #eee3fc;\n    --lighter-pink: #fbf7ff;\n    --main-color: #257EEA;\n    /* measures */\n  }\n  .custom-video-nav {\n    border-radius: 8px;\n    background-color: var(--main-color);\n    width: 100%;\n    border: none !important;\n    overflow-x: auto;\n    padding-left: 4px;\n  }\n  \n  .custom-video-nav::-webkit-scrollbar {\n    height: 15px;\n  }\n  \n  .custom-video-nav-item {\n    display: block;\n    align-items: center;\n    padding: 2px 6px;\n    background-color: var(--main-color);\n    margin: 6px 0 5px;\n    border-radius: 6px;\n    color: #fff;\n    font-size: 12px;\n    font-family: 'Montserrat' sans-serif;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis !important;\n    cursor: pointer;\n    width: 5rem;\n    text-align: center;\n  }\n  .custom-video-nav-item.active {\n    background-color: white;\n    color: var(--main-color);\n  }\n  \n  .custom-video-nav > ul {\n    border: none;\n  }\n  \n  .custom-video-nav::-webkit-scrollbar {\n    width: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
