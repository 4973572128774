// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDDatePicker from "components/MDDatePicker";

import { MenuItem, Select } from "@mui/material";

import MDTimePicker from "components/MDTimePicker/MDTimePicker";
function DateTimePicker({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { twitter, facebook, instagram } = formField;
  const { twitter: twitterV, facebook: facebookV, instagram: instagramV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
      Appointment Date & Time
      </MDTypography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MDDatePicker />
        </Grid>

        <Grid item xs={12} sm={6}>
        <Select>
           
            <MenuItem value="+5:30">Calcutta (+5:30)</MenuItem>
          </Select>
          <MDTimePicker/>
        </Grid>
      </Grid>

    </MDBox>
  );
}

// typechecking props for Socials
DateTimePicker.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DateTimePicker;
