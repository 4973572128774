const formData = {
    formField: {
      firstName: {
        name: 'firstName',
        label: 'First Name',
        placeholder: 'Enter your first name',
        type: 'text',
      },
      lastName: {
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Enter your last name',
        type: 'text',
      },
      company: {
        name: 'company',
        label: 'Company',
        placeholder: 'Enter your company name',
        type: 'text',
      },
      email: {
        name: 'email',
        label: 'Email',
        placeholder: 'Enter your email',
        type: 'email',
      },
      password: {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter your password',
        type: 'password',
      },
      repeatPassword: {
        name: 'repeatPassword',
        label: 'Repeat Password',
        placeholder: 'Repeat your password',
        type: 'password',
      },
    },
    values: {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      password: '',
      repeatPassword: '',
    },
    errors: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
    },
    touched: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      repeatPassword: false,
    },
  };
  
  export default formData;
  