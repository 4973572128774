/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function ServiceAndProvider({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { address1, address2, city, zip } = formField;
  const { address1: address1V, address2: address2V, city: cityV, zip: zipV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Service And Provider
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              options={["Business Meeting", "Long Meeting", "Meeting"]}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Service" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={["State 1", "State 2", "State 3"]}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Provider" />}
            />
          </Grid>
         
        </Grid>
     
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
ServiceAndProvider.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ServiceAndProvider;
