// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-fade-in {
    padding: 10px 0px;
    opacity: 0;
    color: #148bfb;
    overflow:hidden;
    transform:translateY(-10px);
    font-weight:medium;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
}

.success-fade-in button:focus {
  box-shadow: none !important;
}

@keyframes fadeIn {
    0% {
      transform:translateY(-10px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform:translateY(0px);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/success-fade-in.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;IACV,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;IACI;MACE,2BAA2B;MAC3B,UAAU;IACZ;IACA;MACE,UAAU;MACV,yBAAyB;IAC3B;AACJ","sourcesContent":[".success-fade-in {\n    padding: 10px 0px;\n    opacity: 0;\n    color: #148bfb;\n    overflow:hidden;\n    transform:translateY(-10px);\n    font-weight:medium;\n    animation-name: fadeIn;\n    animation-duration: 1s;\n    animation-delay: 5s;\n    animation-fill-mode: forwards;\n}\n\n.success-fade-in button:focus {\n  box-shadow: none !important;\n}\n\n@keyframes fadeIn {\n    0% {\n      transform:translateY(-10px);\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n      transform:translateY(0px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
